import {isApiResponseValid} from "@/helpers/helpers";
import {get, patch, post, remove} from "@/services/api";
import {AppError} from "@/services/errors";
import store from "@/store";

/**
 * Get current user data from localStorage or from api
 * @param {boolean} getFromStorage - if can get data from local storage
 * @return {object} - user data
 */
async function getCurrentUser(getFromStorage = true) {
    try {
        const response = await get('users/me');

        // check response
        if (!isApiResponseValid(response)) {
            throw new AppError('Error loading data', {"message": 'Chyba při načítání uživatele, zkuste obnovit stránku.', 'type': 'warning'})
        }
        // update data
        return response.data;
    } catch (err) {
        if (err instanceof AppError && err.data) {
            store.dispatch('app/addFlashMessage', err.data)
        } else {
            throw err;
        }
    }
}

/**
 * Get all users from api
 * @return {list} - user data
 */
async function getAllUsers() {
    try {
        const response = await get('users/');

        // check response
        if (!isApiResponseValid(response)) {
            throw new AppError('Error loading data', {"message": 'Chyba při načítání uživatelů, zkuste obnovit stránku.', 'type': 'warning'})
        }

        // update data
        return response;
    } catch (err) {
        if (err instanceof AppError && err.data) {
            store.dispatch('app/addFlashMessage', err.data)
        } else {
            throw err;
        }
    }
}

/**
 * Get all users from api
 * @return {list} - user data
 */
async function getTrainingUsers(training_id) {
    try {
        const response = await get(`users/training/${training_id}`);

        // check response
        if (!isApiResponseValid(response)) {
            throw new AppError('Error loading data', {"message": 'Chyba při načítání uživatelů, zkuste obnovit stránku.', 'type': 'warning'})
        }

        // update data
        return response;
    } catch (err) {
        if (err instanceof AppError && err.data) {
            store.dispatch('app/addFlashMessage', err.data)
        } else {
            throw err;
        }
    }
}

/**
 * Make api call to user login
 * @param {string} firstname - firstname
 * @param {string} lastname - lastname
 * @param {string} email - email
 * @param {string} password - password
 * @param {string} phone - phone
 * @param {boolean} newsletter_subscribed - newsletter
 * @param {boolean} university_student - student
 * @param {string} source - zdroj uživatele (např. digiskills)
 * @return {json} - return response from api
 */
async function createUser(firstname, lastname, email, password, phone = '', newsletter_subscribed = false, university_student = false, source= null) {
    return post('users', {
        firstname,
        lastname,
        phone,
        email,
        password,
        newsletter_subscribed,
        university_student,
        language: 27,
        source
    }, false)
}

/**
 * Make api call to update user data
 * @param {number} userId - userId
 * @param {string} firstname - firstname
 * @param {string} lastname - lastname
 * @param {string} email - email
 * @param {string} phone - phone
 * @param {boolean} newsletter_subscribed - newsletter
 * @param {boolean} university_student - student
 * @param {boolean} contacted - zda byl uživatel kontaktován
 * @param {date} contacted_date - kdy byl uživatel kontaktován
 * @return {json} - return response from api
 */
async function updateUser(userId, firstname, lastname, email, phone = '', newsletter_subscribed = false, university_student = false, contacted = null, contacted_date = null) {
    let data = {
        firstname,
        lastname,
        phone,
        email,
        newsletter_subscribed,
        university_student,
        language: 27
    }
    if (contacted !== null)
        data["contacted"] = contacted
    if (contacted_date !== null)
        data["contacted_date"] = contacted_date
    return patch(`users/${userId}`, data, false)
}

/**
 * Make api call to update user data
 * @param {number} id - Id
 * @param {number} training_id - userId
 * @param {number} user_id - userId
 * @param {string} firstname - firstname
 * @param {string} lastname - lastname
 * @param {string} email - email
 * @param {string} phone - phone
 * @param {boolean} mpsv_active
 * @param {string} comment - comment
 * @param {string} comment_up - comment_up
 * @param {string} motivation - motivation
 * @param {string} highest_edu - highest_edu
 * @param {string} field - field
 * @param {string} language_edu - language_edu
 * @param {string} mpsv_url - mpsv_url
 * @param {number} origin - origin
 * @param {string} interest_state - interest_state
 * @param {string} mpsv_code - mpsv_code
 * @param {string} reg_number_npo - reg_number_npo
 * @param {string} mpsv_number - mpsv_number
 * @param {string} candidates_address - candidates_address
 * @return {json} - return response from api
 */
async function updateTrainingUser(id, training_id, user_id = null, firstname, lastname, email, phone = '', mpsv_active, comment, comment_up, motivation, highest_edu, field, language_edu, mpsv_url, origin, interest_state, mpsv_code, reg_number_npo, mpsv_number, candidates_address) {
    let data = {
        id,
        training_id,
        user_id,
        firstname,
        lastname,
        email,
        phone,
        mpsv_active,
        comment,
        comment_up,
        motivation,
        highest_edu,
        field,
        language_edu,
        mpsv_url,
        origin,
        interest_state,
        mpsv_code, 
        reg_number_npo, 
        mpsv_number, 
        candidates_address
    }
    
    return patch(`users/training/${id}`, data, false)
}

/**
 * Make api call to update user data
 * @param {number} id - Id
 * @return {json} - return response from api
 */

async function deleteTrainingUser(id) {
    let data = {
        id       
    }

    return remove(`users/training/${id}`, data, false)
}



export const userService = {
    getCurrentUser,
    createUser,
    updateUser,
    updateTrainingUser,
    deleteTrainingUser,
    getAllUsers,
    getTrainingUsers
};