import {get, post} from "@/services/api";
import store from "@/store";
import _ from "lodash";

/**
 * Get data form api
 * @param {string} endpoint - endpoint
 * @return {Object} - codebooks
 */
async function getDataFromApi(endpoint) {
    try {
        const response = await get(endpoint, {}, {
            params: {
                language: 27,
                academy_id: 1
            }
        }, false);

        return response.data;
    } catch (err) {
        store.dispatch('codebooks/addFlashMessage', {"message": 'Chyba při načítání dat, zkuste obnovit stránku.', 'type': 'warning'})
    }
}

/**
 * Get version from api
 * @return {Object} - codebooks
 */
async function getVersion() {
    try {
        const response = await get('codebooks/version', null, null, false);

        return response.data;
    } catch (err) {
        store.dispatch('codebooks/addFlashMessage', {"message": 'Chyba při načítání dat, zkuste obnovit stránku.', 'type': 'warning'})
    }
}

/**
 * Get data from localStorage
 * @param {string} endpoint - endpoint
 * @return {Object} - codebooks
 */
function getDataFromLocalStorage(endpoint) {
    // get from localStorage
    let data = localStorage.getItem(endpoint);

    // if exist parse
    if (data) {
        data = JSON.parse(data);
    }

    store.dispatch('codebooks/setAllCodebooks', {endpoint, data});
    return data;
}

/**
 * Save data to localStorage
 * @param {string} endpoint - endpoint
 * @param {Object} data - data
 */
function saveDataToLocalStorage(endpoint, data) {
    localStorage.setItem(endpoint, JSON.stringify(data));
    store.dispatch('codebooks/setAllCodebooks', {endpoint, data});
}

/**
 * Get data codebooks and translations data from api to localstorage, check version
 */
async function getAllCodebooks() {
    // get local version
    const versionLocal = localStorage.getItem('codebooksVersion')

    // get api version
    const versionApi = await getVersion();

    // get version match
    const matchVersions = versionApi === versionLocal;

    // foreach endpoints and check version
    for (const endpoint of ['codebooks', 'translations']) {
        let dataApi;

        // check if version matches
        if (!matchVersions) {

            // load from api
            dataApi = await getDataFromApi(endpoint)

            // update localstorage
            saveDataToLocalStorage(endpoint, dataApi)
        } else {
            try {
                // try to get data from localstorage
                getDataFromLocalStorage(endpoint)
            } catch (err) {
                // load from api
                dataApi = await getDataFromApi(endpoint)

                // update localstorage
                saveDataToLocalStorage(endpoint, dataApi)
            }
        }
    }

    // update local version
    if (!matchVersions) {
        localStorage.setItem('codebooksVersion', versionApi);
    }
}

/**
 * Get codebooks values
 * @param {object} data - data
 * @param {string} key - codebook key
 * @return {object} - codebooks values
 */
function getCodeBookByKey(data, key) {
    let out = {};

    if (_.has(data, key)) {
        out = data[key];
    }

    return out;
}

/**
 * Get codebook value
 * @param {object} data - data
 * @param {string} key - codebook key
 * @param {string} value - codebook value
 * @return {string} - codebook value
 */
function getCodeBookByKeyAndValue(data, key, value) {
    let out = '';

    data = getCodeBookByKey(data, key);

    if (_.has(data, value)) {
        out = data[value]
    }
    return out;
}

/**
 * Get translation by placeholder
 * @param {object} data - data
 * @param {string} placeholder - placeholder
 * @param {object|null} attrs - object with dynamic data {number: 8}
 * @example
 * // default
 * translation('string')
 * @example
 * // with attrs
 * translation('...string %number% string...', {number: 8})
 * @return {string} - translation
 */
function translation(data, placeholder, attrs = null) {
    let out = '';

    // check if key exist in array of translations
    if (_.has(data, placeholder)) {
        out = data[placeholder];

        if (attrs) {
            for (const key in attrs) {
                out = _.replace(out, `%${key}%`, attrs[key])
            }
        }
    }

    return out;
}

/**
 * Add translation to api
 * @param {string} key - key of translation
 * @param {string} text - text of translation
 * @param {string} language - locale id
 * @return {object} - success message
 */
function createTranslation(key, text, language) {
    return post('users/codebooks');
}

export const codebooksService = {
    getAllCodebooks,
    getCodeBookByKey,
    translation,
    getCodeBookByKeyAndValue,
    createTranslation,
};